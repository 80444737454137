<template>
	<div>
		<div class="split-bg">
			<div style="width: 41%;height: 100%; background-color: #CCE3FD "></div>
		</div>
		<div class="vertical-align-wrap" style="z-index: 2">
			<div class="vertical-align-top" style="z-index: 2;height: 100%">
				<div class="auth-box">
					<div class="left">
						<div class="content">
                            <div class="logo text-center mt-4 mb-3">
								<a href>
									<img height="55" :src="logo" alt="Boscod Logo" />
								</a>
							</div>

                            <!-- Form Register -->
                            <form @submit="submitForm" ref="formSubmit" class="form-auth-small" method="post">
                                <div :class="alertStatus" v-if="showMessage">{{ textMessage }}</div>
                                <div class="form-group">
									<label for="name" class="control-label float-left" style="color:#62676F;">Nama Lengkap</label>
									<input type="text" class="form-control" id="name" v-model="form.name" ref="name" required minlength="4" pattern="([A-z\s]){2,}" placeholder="Cth: Budiman" />
								</div>
                                <div class="form-group">
									<label for="email" class="control-label float-left" style="color:#62676F;">Email</label>
									<input class="form-control" ref="email" id="email" required v-model="form.email" placeholder="Cth: budiman@gmail.com" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" />
								</div>
                                <div class="form-group">
									<label for="tel" class="control-label float-left" style="color:#62676F;">Nomor Telepon</label>
									<input type="number" class="form-control" id="tel" ref="tel" required v-model="form.tel" onKeyPress="if(this.value.length==20) return false;" placeholder="Cth: 081xxxxxxxxx" />
								</div>
                                <div class="form-group">
                                    <label for="signin-password" class="control-label float-left" style="color:#62676F;">Password</label>
                                    <div class="input-group">
                                        <input :type="showPassword?'text':'password'" class="form-control border-right-0" ref="pass" minlength="8" required v-model="form.pass" placeholder="Minimal 8 Karakter" />
                                        <div class="input-group-append" style="cursor: pointer;">
                                            <div class="input-group-text bg-white border-left-0" v-on:click="toggleShowPassword">
                                                <span v-show="!showPassword"><span class="fas fa-eye-slash"></span></span>
                                                <span v-show="isShowPassword"><span class="fas fa-eye"></span></span>
                                                <span v-show="passStat == 100"><span class="fas fa-check text-success ml-2"></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="progress rounded-pill mt-2" v-if="true">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" :class="[passClass]" role="progressbar" v-bind:aria-valuenow="passStat" aria-valuemin="0" aria-valuemax="100" :style="{ 'width': passStat + '%' }"></div>
                                    </div>
                                    <label class="d-block text-left font-weight-normal mt-1" style="color:#62676F;" v-html="passMsg"></label>
                                </div>
                                <div class="form-group">
                                    <label for="signin-password" class="control-label float-left" style="color:#62676F;">Ulangi Password</label>
                                    <div class="input-group">
                                        <input :type="showPasswordRepeat?'text':'password'" class="form-control border-right-0" ref="pass2" required v-model="form.pass2" placeholder="Minimal 8 Karakter" />
                                        <div class="input-group-append" style="cursor: pointer;">
                                            <div class="input-group-text bg-white border-left-0" v-on:click="toggleShowPasswordRepeat">
                                                <span v-show="!showPasswordRepeat"><span class="fas fa-eye-slash"></span></span>
                                                <span v-show="isShowPasswordRepeat"><span class="fas fa-eye"></span></span>
                                                <span v-show="form.pass == form.pass2"><span class="fas fa-check text-success ml-2"></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="estimasi" class="control-label float-left" style="color:#62676F;">Estimasi Jumlah Kiriman Harian</label>
                                    <select id="estimasi" class="form-control" v-model="form.shipping_amount" required>
                                        <option disabled selected>Pilih Estimasi Jumlah Kiriman</option>
                                        <option value="5">1 - 5</option>
                                        <option value="30">6 - 30</option>
                                        <option value="100">31 - 100</option>
                                        <option value="1000"> > 100</option>
                                    </select>
                                </div>
                                <div class="form-group">
									<label for="referral" class="control-label float-left" style="color:#62676F;">Kode Afiliasi (tidak wajib diisi)</label>
									<input type="text" class="form-control" id="referral" ref="referral" minlength="6" maxlength="6" v-model="form.referral" v-on:change="referralCheck" />
								</div>
                                <small v-if="referral.loading" class="text-info">Sedang mencari referal</small>
                                <small v-if="referral.failed" class="text-danger">Referal tidak terdaftar</small>
                                <small v-if="referral.success" class="text-success">Berhasil mendapat bonus</small>
                                <div class="col-12 text-left">
                                    <div>
                                        <div class="icheck-material-orange icheck-inline">
                                            <input type="checkbox" required id="chb1" />
                                            <label for="chb1" style="font-weight:normal;">Setuju dengan <a v-on:click="showTerm"><strong style="color: #FF7203;">Ketentuan dan Kebijakan Privasi</strong></a></label>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" :disabled="loading" class="btn btn-primary btn-lg btn-block" style="border-radius: 4.8px; height: 42px; padding: 0px;">
									<span v-if="loading" class="spinner-border spinner-border-sm" role="status"
										aria-hidden="true"></span>
									{{ loading?" Registrasi...":"Registrasi" }}
								</button>
                                <div class="bottom mb-4">
									<br />
									<span style="color: #9699A0;">
                                        Sudah punya akun? &nbsp;<b><router-link style="color: #2F4858;" to="/login">Login</router-link></b>
									</span>
									<br />
								</div>
                            </form>
						</div>
					</div>
					<div class="right">
                        <img src="@/assets/img/register.svg" width="60%" style="display: block;margin: 0 auto;" alt="Register Image" />
						<div class="clearfix"></div>
					</div>
				</div>
			</div>
		</div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form">
                        <div class="modal-header">
                            <h5 class="modal-title">Ketentuan & Kebijakan Privasi</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" style="height: 35vh; overflow-y: auto;">
                            <strong>Terms & Conditions</strong>
                            <ol class="terms">
                                <li>BosCOD merupakan platform multi kurir COD dan NonCOD</li>
                                <li>BosCOD mengedepankan sukses delivery dan zero return without confirmation</li>
                                <li>BosCOD tidak memberikan jaminan nilai/angka retur</li>
                                <li>Segala bentuk pembayaran akan dilakukan setelah paket sukses dengan periode 1-7 hari setelah sukses</li>
                                <li>Apabila dikemudian hari ada kendala terhadap angka atau kasus return with confirmation atau return without confirmation, maka akan diselesaikan dengan mediasi BosCOD dan dianggap keputusan Final.</li>
                                <li>Shipper atau pengirim dilarang mengirimkan Dangerous Goods dan Barang yang dilarang negara Republik Indonesia.</li>
                                <li>Kami menjamin kerahasiaan dan privasi customer.</li>
                            </ol>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
import { authFetch } from '@/libs/hxcore';
import Vue from 'vue'
import $ from 'jquery';
import "icheck-material";
import { required, minLength, between } from "vuelidate/lib/validators";

export default {
    name: 'Registrasi',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            groups: [],
            formTitle: 'Registrasi',
            showMessage: false,
            loading: false,
            textMessage: '',
            alertStatus: '',
            captchaLink: '/users/register/captcha',
            form: {
                name: '',
                email: '',
                tel: '',
                pass: '',
                pass2: '',
                referral: '',
                agree: '',
                shipping_amount: ''
            },
            referral: {
                loading: false,
                failed: false,
                success: false,
            },
			failed: false,
			failedMsg: "",
			message: "",
			isLoginView: true,
			resetAction: false,
			resetClass: "",
			email: "",
			logo: '',
            showPassword: false,
            showPasswordRepeat: false,
            passClass: 'bg-danger',
            passStat: 0,
            passMsg: '',
        }
    },
    computed: {
        isShowPassword: function () {
            return this.showPassword;
        },
        isShowPasswordRepeat: function () {
            return this.showPasswordRepeat;
        },
        pass: function () {
            return this.form.pass;
        },
    },
    watch: {
        pass: function (val) {
            if (val.length < 8) {
                this.passClass = 'bg-danger';
                this.passStat = 0;
                this.passMsg = '<span class="text-danger">Password minimal 8 karakter</span>';
            } else if (!(/[a-z]/.test(val) && /[A-Z]/.test(val))) {
                this.passClass = 'bg-danger';
                this.passStat = 25;
                this.passMsg = '<b>Kekuatan: <span class="text-danger">Lemah</span>.</b> Setidaknya memuat minimal 1 huruf ' + (/[a-z]/.test(val) ? 'besar' : 'kecil');
            } else if (!(/\d/.test(val))) {
                this.passClass = 'bg-yellow';
                this.passStat = 50;
                this.passMsg = '<b>Kekuatan: <span class="text-yellow">Cukup</span>.</b> Setidaknya memuat minimal 1 angka';
            } else if (!(/[^a-zA-Z 0-9]+/.test(val))) {
                this.passClass = 'bg-info';
                this.passStat = 75;
                this.passMsg = '<b>Kekuatan: <span class="text-info">Bagus</span>.</b> Tambahkan minimal 1 karakter spesial';
            } else {
                this.passClass = 'bg-success';
                this.passStat = 100;
                this.passMsg = '<b>Kekuatan: <span class="text-success">Sempurna</span>.</b> Kamu punya password yang kuat';
            }
        }
    },
    created: function() {
        // $('body').addClass('register-page');
        $('body').removeAttr('style');
        this.captchaUrl = window.$apiUrl + this.captchaLink;
        if(Vue.config.appHost == 'xpress')
            this.logo = require(`../assets/img/logo-xpress.png`);
        else this.logo = require(`../assets/img/logo-min.svg`);
            
        authFetch("/users/register/version")
        .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
        })
        .then((js) => {
                this.roles = js.roles;
        });
    },
    validations: {
		form: {
			username: {
				required
			},
			password: {
				required
			}
		}
	},
    mounted() {
        if (this.$route.query.referral) {
            this.form.referral = this.$route.query.referral;
            this.referralCheck();
        }
        if (this.$route.params.id) {
            this.form.referral = this.$route.params.id;
            this.referralCheck();
        }
        if (this.$route.params.user) {
            var gUser = this.$route.params.user;
            this.form.name = gUser.name;
            this.form.email = gUser.email;
            this.form.gmail = gUser.email;
            this.form.token = gUser.id_token;
            this.form.image_url = gUser.picture;
        }
    },
    beforeDestroy() {
        $('body').removeClass('register-page');
    },
    methods: {
      toggleShowPassword: function(e)
      {
        console.log( e.target);
        this.showPassword = !this.showPassword;
      },
      toggleShowPasswordRepeat: function(e)
      {
        console.log( e.target);
        this.showPasswordRepeat = !this.showPasswordRepeat;
      },
      showTerm: function()
      {
            const e = this.$refs;
            $(e.formDialog).modal("show");
      },
      resetForm: function()
      {
        for (const key in this.$refs) {
          this.$refs[key].setAttribute('title', '');
          this.$refs[key].classList.remove('is-invalid');
        }
      },
      clearForm: function()
      {
        this.form = {
            name: '',
            email: '',
            tel: '',
            pass: '',
            pass2: '',
            referral: '',
            agree: '',
        };
        this.referral.loading = false;
        this.referral.failed = false;
        this.referral.success = false;
      },
      referralCheck: function()
      {
        if (this.form.referral.length < 6) {
          return false;
        }
        this.referral.loading = true;
        this.referral.failed = false;
        this.referral.success = false;
        fetch(window.$apiUrl + '/users/register/referral/' + this.form.referral)
        .then((res) => {
          if (res.status === 201) {} else if (res.status === 400) {}
          return res.json();
        })
        .then((js) => {
          if (js.success) {
            this.referral.success = true;
          } else {
            this.referral.failed = true;
          }
          this.referral.loading = false;
        });
      },
        submitForm: function(e) {
          this.resetForm();
          this.showMessage = false;
          this.loading = true;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            fetch(window.$apiUrl + '/users/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: data
            })
            .then(res => {
              this.loading = false;
                if (res.status === 201) {
                } else if (res.status === 400) {
                    //let errorResponse = await res.json();
                    //this.errors.push(errorResponse.error);
                }
                return res.json();
            })
            .then(js => {
              if(js.status){
                this.textMessage = js.message;//'Registrasi berhasil. Silahkan periksa email untuk verifikasi akun.';
                this.showMessage = true;
                this.clearForm();
                  this.alertStatus = 'alert alert-success';
              }
              else if(!js.status)
              {
                if(js.type=='error_input')
                {
                  for (const key in js.details) {
                    if(this.$refs[key])
                    {
                      this.$refs[key].setAttribute('title', js.details[key]);
                      this.$refs[key].classList.add('is-invalid');
                    }
                    
                  }
                }
                else{
                  this.textMessage = js.message;
                  this.showMessage = true;
                  this.alertStatus = 'alert alert-danger';
                }
              }
                console.log(js)
            });
            e.preventDefault();
        },
        reloadCaptcha: function()
        {
          this.$refs.imgc.src = this.captchaUrl;
        },
        filterRoles(role) {
            return this.roles.filter(n=>n===role).length===0?false:true; 
        },
    }
}
</script>
<style scoped src="../assets/login.css"></style>
<style scoped>
.widget-block {
	height: 100%;
}

.split-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	display: none
		/*table*/
	;
	background-color: #21316f;
	/*background-image: url(../../assets/img/repeat-bg.jpg);*/
	background-repeat: repeat repeat;
	z-index: 1;
}

.w-100-1 {
	/*width: 70%!important;
      height: 50%;*/
	/*height: -webkit-fill-available;*/
}

.carousel-indicators .active {
	height: 11px;
	width: 44px;
    background-color: #FF7203;
    border-radius: 100px;
}

.vertical-align-wrap {
	background-color: transparent;
	background-image: none;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
	height: 100%;
}

.auth-box {
	border-radius: 15px;
	width: 100%;
	height: 100%;
    overflow-y: auto;
	/*height: 600px*/
}

.carousel-indicators li {
	border-top: none;
	border-bottom: none;
	height: 11px;
    width: 15px;
	display: inline-block;
	background-color: #FFCCA3;
    border-radius: 100px;
}

.carousel-inner {
	height: 100%;
	/*border-radius: 0px 15px 15px 0px;*/
}

.carousel-indicators {
	display: block;
	text-align: center;
	width: 70%;
}

.carousel-caption {
	bottom: auto;
	margin-top: -30px;
	vertical-align: middle;
}

.right-side {
	float: right;
	width: 60%;
	height: 100%;
	position: relative;
}

.auth-box .right {
    float: right;
    width: 60%;
    background-color: #FFF1E6;
    /* border-radius: 15px; */
    height: 100vh;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.auth-box .right:before {
	display: contents;
}

.auth-box .left {
	float: left;
	width: 40%;
	height: 100%;
	padding: 0 60px;
	text-align: center;
}

@media screen and (max-width: 1023px) {
	.auth-box .left {
		width: 100%;
		position: inherit;
		padding: 20px;
	}

	.auth-box .right {
        display: none;
	}

	.split-bg {
		display: none;
	}

	.vertical-align-wrap {
		/*background-color: #21316F;*/
		/*background-image: url(../../assets/img/repeat-bg.jpg);*/
		background-repeat: repeat repeat;
		z-index: 1;
	}
}

@media screen and (max-width: 1279px) {
	.auth-box .left {
		top: 3.3em;
	}

	.split-bg {
		display: none;
	}

	.vertical-align-wrap {
		/*background-color: #21316F;*/
		/*background-image: url(../../assets/img/repeat-bg.jpg);*/
		background-repeat: repeat repeat;
		z-index: 1;
	}
}

.carousel {
	height: 100%;
	background-color: #FFF1E6;
	/* background: linear-gradient(261deg,
			rgba(237, 237, 237, 0) 44%,
			rgba(31, 174, 203, 0.23713235294117652) 100%); */
}

.carousel-inner img {
	/*min-height: 100%;*/
	background-size: cover;
	/*height: -webkit-fill-available;*/
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
}

/********************************/
/*            Overlay           */
/********************************/
.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 2;
	background-color: #080d15;
	opacity: 0.5;
}

body {
	color: #676a6d;
}

.form-group {
	margin-bottom: 15px;
}

.form-control {
	height: 34px;
	padding: 6px 12px;
}

.btn-lg {
	padding: 10px 28px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #DDDDDD;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.googlebtn{
    color: #FF7203;
    border: 2px solid #FF7203;
    background-color: white;
    border-radius: 4px;
}

.googlebtn:hover{
    color: #944100;
    border: 2px solid #944100;
    background-color: white;
    border-radius: 4px;
}
</style>